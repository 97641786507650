import React from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayer = (props) => {
  console.log(props.setting, "dashboardSettingData");

  const setVideoPlayed = () => {
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  return (
    <div className="wrapper">
      <ReactPlayer
        controls
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
        onPlay={setVideoPlayed}
      />
    </div>
  );
};

export default ReactVideoPlayer;
