import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EditEventApi } from "src/DAL/Calender/Calender";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function EditEvent({
  editValues,
  dataList,
  setShowEditComponent,
  setEditValues,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const [state, setState] = useState({
    eventDescription: "",
    eventTitle: "",
    eventSlug: "",
    eventColor: "black",
    startTime: "00:00",
    endTime: "00:00",
    itrationNumber: 1,
    recurringType: "daily",
    startDate: new Date(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (event) => {
    console.log(event);
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event.$d,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var dateString = moment(state.startDate).format("YYYY-MM-DD");
    const formDataObject = {
      title: state.eventTitle,
      color: state.eventColor,
      recurring_type: state.recurringType,
      start_date: dateString,
      recurring_iteration: state.itrationNumber,
      description: shortDescriptionCk,
      start_time: state.startTime,
      end_time: state.endTime,
    };
    console.log(formDataObject, "formDataObject");
    const result = await EditEventApi(state.eventSlug, formDataObject);
    let eventObject = {};
    if (result.code === 200) {
      let event = result.event;
      eventObject = {
        event_id: event._id,
        title: event.title,
        event_title: event.title,
        date: event.start_date,
        start_date: event.start_date,
        end_date: event.end_date,
        start_time: event.start_time,
        end_time: event.end_time,
        recurring_iteration: event.recurring_iteration,
        description: event.description,
        event_type: event.event_type,
        color: event.color,
        event_color: event.color,
        recurring_type: event.recurring_type,
        event_slug: event.event_slug,
      };

      setEditValues(eventObject);
      dataList();
      setIsLoading(false);
      setShowEditComponent(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ["eventTitle"]: editValues.event_title,
      ["eventColor"]: editValues.event_color,
      ["recurringType"]: editValues.recurring_type,
      ["startDate"]: editValues.start_date,
      ["itrationNumber"]: editValues.recurring_iteration,
      ["eventDescription"]: editValues.description,
      ["startTime"]: editValues.start_time,
      ["endTime"]: editValues.end_time,
      ["eventSlug"]: editValues.event_slug,
    }));
    setShortDescriptionCk(editValues.description);
  }, [editValues]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4"
          id="outlined-basic"
          label="Event Title"
          variant="outlined"
          name="eventTitle"
          value={state.eventTitle}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD/MM/YYYY"
            mask="__/__/____"
            inputFormat="DD/MM/YYYY"
            label="Start Date"
            name="startDate"
            value={state.startDate}
            onChange={(e) => handleChangeDate(e)}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
          {/* <div className="mt-3">
            <TimePicker
              label="Start Time"
              value={state.startTime}
              onChange={(newValue) => {
                setState({ ...state, startTime: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>

          <div className="mt-3">
            <TimePicker
              label="End Time"
              value={state.endTime}
              onChange={(newValue) => {
                setState({ ...state, endTime: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div> */}
        </LocalizationProvider>
        <TextField
          id="color"
          type="color"
          label="Event Color"
          name="eventColor"
          className="mt-3"
          variant="outlined"
          required={true}
          value={state.eventColor}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          variant="outlined"
          id="time"
          label="Start Time"
          type="time"
          className="mt-3"
          name="startTime"
          required={true}
          value={state.startTime}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          variant="outlined"
          id="time"
          label="End Time"
          type="time"
          className="mt-3"
          name="endTime"
          required={true}
          value={state.endTime}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          id="outlined-basic"
          label="Number of Times To Repeat"
          variant="outlined"
          name="itrationNumber"
          value={state.itrationNumber}
          required={true}
          onChange={(e) => handleChange(e)}
          type="number"
          className="mt-3"
        />
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Recurring Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.recurringType}
            onChange={(e) => handleChange(e)}
            label="recurringType"
            name="recurringType"
            className="svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="daily">
              <em>Daily</em>
            </MenuItem>
            <MenuItem value="weekly">
              <em>Weekly</em>
            </MenuItem>
            <MenuItem value="monthly">
              <em>Monthly</em>
            </MenuItem>
          </Select>
        </FormControl>
        {/* <TextField
          id="outlined-multiline-static"
          label="Event Description"
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%" }}
          name="eventDescription"
          value={state.eventDescription}
          onChange={(e) => handleChange(e)}
          className="mt-3 inputs-fields"
          required={true}
        /> */}
        {/* <div className="mt-3">
          <Editor
            className="inputs-fields"
            content={state.eventDescription}
            setContent={(value) =>
              setState({
                ...state,
                eventDescription: value,
              })
            }
          />
        </div> */}
        <div className="col-12 mt-4">
          <h4>Event Description </h4>
          <TinyEditor
            setDetailDescription={setShortDescriptionCk}
            detailDescriptionCk={shortDescriptionCk}
          />
        </div>
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
