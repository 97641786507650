import React, { useState, useEffect } from "react";
import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import AddEvent from "./AddEvent";
import EventDetail from "./EventDetail";
import { eventsListing } from "src/DAL/Calender/Calender";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import { get_date_with_user_time_zone } from "src/utils/constants";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Calender(props) {
  const { userInfo, adminTimeZone } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const [eventsList, setEventsList] = useState([]);

  const [finalEvents, setFinalEvents] = useState([]);

  const [eventDetailData, setEventDetailData] = useState([]);
  const [imgUrl, setimgUrl] = useState("");
  const [isOpenImage, setisOpenImage] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerState, setDrawerState] = useState(false);
  const classes = useStyles();
  const { contentSettingData } = useContentSetting();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowImageDialog = (url) => {
    setisOpenImage(!isOpenImage);
    setisOpen(!isOpen);
    console.log("url", url);
    setimgUrl(url);
  };
  const handleShowDialog = () => {
    setisOpen(!isOpen);
  };

  const handleCloseDialog = () => {
    setisOpen(false);
    setisOpenImage(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setEditDrawerState(true);
  };
  const renderEventContent = (eventInfo) => {
    return (
      <Tooltip title={htmlDecode(eventInfo.event.title)}>
        <div
          className="calender-events-box"
          style={{ cursor: "pointer" }}
          onClick={() => eventDetail(eventInfo.event._def.extendedProps)}
        >
          <b
            className="calender-events-text"
            style={{
              backgroundColor: eventInfo.event._def.extendedProps.event_color,
            }}
          >
            {eventInfo.event.title.length > 15
              ? `${htmlDecode(eventInfo.event.title.substring(0, 15))}...`
              : htmlDecode(eventInfo.event.title)}
          </b>
        </div>
      </Tooltip>
    );
  };

  const getEventsList = (event) => {
    console.log(event, "top event");
    const {
      end_date,
      start_date,
      start_time,
      recurring_type,
      recurring_iteration,
      end_time,
    } = event;

    let datesArray = [];
    let all_events = [];
    let datesArrayNew = datesArray.push(start_date);
    if (recurring_type === "daily") {
      for (let i = 0; i < Number(recurring_iteration); i++) {
        var new_date = moment(
          moment(start_date).add(i, "d").format("YYYY-MM-DD")
        );
        all_events.push({
          ...event,
          date: new_date._i,
          event_color: event.color,
          event_title: event.title,
        });
      }
      return all_events;
    } else if (recurring_type === "weekly") {
      for (let i = 0; i < Number(recurring_iteration); i++) {
        var new_date = moment(
          moment(start_date).add(i, "week").format("YYYY-MM-DD")
        );

        all_events.push({
          ...event,
          date: new_date._i,
          event_color: event.color,
          event_title: event.title,
        });
      }
      return all_events;
    } else if (recurring_type === "monthly") {
      for (let i = 0; i < Number(recurring_iteration); i++) {
        var new_date = moment(
          moment(start_date).add(i, "month").format("YYYY-MM-DD")
        );
        all_events.push({
          ...event,
          date: new_date._i,
          event_color: event.color,
          event_title: event.title,
        });
      }
      return all_events;
    }

    return [];
  };
  const getEventListing = async () => {
    const result = await eventsListing();
    console.log(result.member_event[0], " result.member_event");
    if (result.code === 200) {
      let all_events = [];
      result.member_event.map((event, index) => {
        let resp_event = getEventsList(event);
        console.log(resp_event, "resp_events");
        all_events = [...all_events, ...resp_event];
      });

      all_events = all_events.map((event, i) => {
        if (event.action_by === "admin_user") {
          return {
            ...event,
            title: event.title,
            color: event.color,
            start: get_date_with_user_time_zone(
              event.date + " " + event.start_time,
              "YYYY-MM-DD HH:mm",
              userInfo.time_zone,
              adminTimeZone
            ),
            end: get_date_with_user_time_zone(
              event.date + " " + event.end_time,
              "YYYY-MM-DD HH:mm",
              userInfo.time_zone,
              adminTimeZone
            ),
          };
        } else {
          return {
            ...event,
            title: event.title,
            color: event.color,
            start: moment(event.date + " " + event.start_time).format(
              "YYYY-MM-DD HH:mm"
            ),
            end: moment(event.date + " " + event.end_time).format(
              "YYYY-MM-DD HH:mm"
            ),
          };
        }
      });

      setEvents(all_events);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEventListing();
  }, []);
  console.log(events, "finalEvents");

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(events, "events");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {contentSettingData && contentSettingData.calender_description ? (
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: contentSettingData.calender_description,
              }}
            ></div>
          ) : (
            <h2 className="quotes-heading">Calender</h2>
          )}
        </div>
        <div className="col-12">
          <button
            className="small-contained-button float-end mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            Add Event
          </button>
        </div>
        <div className="col-md-12 full-calendar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "today prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
            eventContent={renderEventContent}
            events={events}
            initialView="dayGridMonth"
            editable={false}
            droppable={false}
            selectable={true}
            defaultView="dayGridMonth"
            selectMirror={true}
            dayMaxEvents={true}
            // slotLabelFormat={{
            //   hour: "2-digit",
            //   minute: "2-digit",
            //   omitZeroMinute: false,
            //   meridiem: "short",
            // }}
            // eventTimeFormat={{
            //   hour: "2-digit",
            //   minute: "2-digit",
            //   meridiem: false,
            //   hour12: false,
            // }}
            weekends={weekendsVisible}
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Event Detail"
        componentToPassDown={
          <EventDetail
            setIsLoading={setIsLoading}
            eventDetailData={eventDetailData}
            dataList={getEventListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Event"
        componentToPassDown={
          <AddEvent
            dataList={getEventListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default Calender;
