import React from "react";
import { Box, Card, Link, Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../../config/config";
import { htmlDecode } from "src/utils/convertHtml";

function ProgrammRecording({ lessonSlug, recording }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (lessonSlug) {
      navigate(`/lessons-recordings/${recording.recording_slug}`, {
        state: recording,
      });
    } else {
      navigate(`/recordings/${recording.recording_slug}`, {
        state: recording,
      });
    }
  };

  return (
    <>
      <Card className="lesson-card" onClick={handleClick}>
        <div className="row p-3">
          <div className="col-lg-2 col-md-3 col-sm-12">
            <img
              className="lesson-card-image"
              src={s3baseUrl + recording.recording_image.thumbnail_2}
              alt="name"
            />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <h4 className="lesson-heading">{htmlDecode(recording.title)} </h4>
            <p className="normal-font">
              {htmlDecode(recording.short_description)}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProgrammRecording;
