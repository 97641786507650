import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

function VideoDetails(props) {
  const navigate = useNavigate();
  return (
    <div className="container new-community-page">
      <section className="success-wrapper pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/dashboard")}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className="col-lg-12">
              <h1>ACHIEVING SUCCESS IS NOT A SECRET, IT’S A SCIENCE</h1>
              <h2>
                <span>MINDSET</span> &nbsp; 2022 &nbsp; 1SEASON
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
            <div className="col-lg-12 mt-5">
              <ReactVideoPlayer url="https://vimeo.com/686454572" />
              {/* <img src={communityVideoImg} alt="" className="img-fluid" /> */}
            </div>
            <div className="col-lg-12 mt-5">
              <h1>ACHIEVING SUCCESS IS NOT A SECRET, IT’S A SCIENCE</h1>
              <h2>
                <span>MINDSET</span> &nbsp; 2022 &nbsp; 1SEASON
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VideoDetails;
