import React from "react";
import { Box, Card, Link, Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../../config/config";
import { htmlDecode } from "src/utils/convertHtml";

function StudySessionCard({ studySession, study_session_slug }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/study-session/${study_session_slug}/study-session-recordings`);
  };
  return (
    <>
      <Card className="lesson-card " onClick={handleClick}>
        <div className="row p-3">
          <div className="col-lg-2 col-md-3 col-sm-12">
            <img
              className="lesson-card-image"
              src={s3baseUrl + studySession.image.thumbnail_2}
              alt="name"
            />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <h4 className="lesson-heading">{htmlDecode(studySession.title)}</h4>
            <p className="normal-font">
              {htmlDecode(studySession.short_description)}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
}

export default StudySessionCard;
