import { seedValue } from "faker";
import React from "react";
import { useParams } from "react-router-dom";
// import { TransactionList } from "./TransactionList";
import TransactionList from "./TransactionList";

const billing = [
  {
    title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    date: "Jan, 17 2022",
    requestType: "Recurring",
    TotalProgramAmount: "$9000",
    InitialDepositAmount: "$9000",
    MonthlyInstallments: "$9000",
    InstallmentsPlan: "$9000",
    paid: "$3000",
    paid_status: true,
  },
];

const BillingDetail = (props) => {
  const params = useParams();
  console.log(params, "slug data");
  return (
    <div className="container">
      <h2 style={{ marginBottom: " 30px" }}>Billing Details</h2>
      <div className="col-lg-12">
        {billing.map((bill, index) => {
          return (
            <div className="card-billing card-margin">
              <div className="card-body pt-4">
                <div className="widget-49">
                  <div className="widget-49-title-wrapper">
                    {/*<div className="widget-49-date-primary">
                        <span className="widget-49-date-day">09 apr</span>
                        <span className="widget-49-date-month">apr</span>
                      </div> */}
                    <div className="widget-49-meeting-info">
                      <span className="widget-49-pro-title">{bill.title}</span>
                      <span className="widget-49-meeting-time">
                        {bill.date}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Request Type:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      {bill.requestType}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Total Programme Amount:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                      {bill.TotalProgramAmount}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Initial Deposit Amount:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                      {bill.InitialDepositAmount}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Monthly Installments:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                      {bill.MonthlyInstallments}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Installments Plan:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                      {bill.InstallmentsPlan}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      Paid Amount:
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                      {bill.InstallmentsPlan}
                    </div>
                  </div>
                  <div className="widget-49-meeting-action text-end">
                    <button className="small-contained-button">View All</button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div col-12>
        <TransactionList />
      </div>
    </div>
  );
};

export default BillingDetail;
