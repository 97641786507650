import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { chanePassword } from "src/DAL/Login/Login";
import { communityHeart, profileImage } from "src/assets";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ShowAllLikes({ openLikesModal, setOpenLikesModal }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openLikesModal}
        onClose={() => {
          setOpenLikesModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="create-post-title-form">
          <Box sx={style} className="modal-theme">
            <div className="text-center modalIcon clo-12">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    // allowScrollButtonsMobile="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab
                      label={
                        <>
                          <img src={communityHeart} alt="" />
                          <span>Likes</span>
                        </>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={
                        <>
                          <img src={communityHeart} alt="" />
                          Gratitude
                        </>
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="row">
                    {/* {pendingRequest.length > 0 ? (
                      <div className="col-12 mt-3">
                        <RequestsList data={pendingRequest} />
                      </div>
                    ) : (
                      <RecordNotFound title="Active Requests" />
                    )} */}
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="row">
                    {/* {activeRequest.length > 0 ? (
                      <div className="col-12 mt-3">
                        <RequestsList data={activeRequest} />
                      </div>
                    ) : (
                      <RecordNotFound title="Active Requests" />
                    )} */}
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="row">
                    {/* {activeRequest.length > 0 ? (
                      <div className="col-12 mt-3">
                        <RequestsList data={activeRequest} />
                      </div>
                    ) : (
                      <RecordNotFound title="Active Requests" />
                    )} */}
                    <div className="col-12 mt-3">
                      <div className="post-creator">
                        <img src={profileImage} alt="" />
                        <div className="creator-name">
                          <h3>Leeanna Alvord</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
