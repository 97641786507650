import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import KimResponce from "./KimResponce";
import CustomDrawer from "../FormsDrewer/CustomDrawer";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ownerName } from "../../config/config";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useSnackbar } from "notistack";
import moment from "moment";
import { InputAdornment } from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import {
  AddSingleAnswerApi,
  AddAllAnswersApi,
} from "src/DAL/GoalStatement/GoalStatementList";
import {
  AddNineteenDay,
  NinteenDayQuestionList,
} from "src/DAL/90Day/NinetyDay";
import NumberFormat from "react-number-format";
import { convertCurrencyToSign } from "src/utils/constants";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import { CircularProgress, IconButton } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Message90Day = () => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [questionMessages, setQuestionMessages] = useState([]);
  const [question, setQuestion] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [date, setDate] = React.useState(null);
  const [amount, setAmount] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [targetDate, setTargetDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getNineteenDayQuestions = async () => {
    const result = await NinteenDayQuestionList();
    const todayDate = result.start_date; //get start date
    setDate(result.start_date);

    const after_ninety_day = new Date(todayDate);

    after_ninety_day.setDate(after_ninety_day.getDate() + 90);
    setTargetDate(moment(after_ninety_day).format("DD MMM YYYY"));
    setAmount(result.target_amount);

    let questionsArray = [];
    if (result.code == 200) {
      setIsLoading(false);
      result.Questions_array.map((question_value, index) => {
        if (index === 0) {
          questionsArray.push({
            ...question_value,
            isExpanded: true,
            isLoading: false,
          });
        } else {
          questionsArray.push({
            ...question_value,
            isExpanded: false,
            isLoading: false,
          });
        }
      });
      setQuestionMessages(questionsArray);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const toggleInput = () => {
    setToggle(!toggle);
  };
  const handleKimResponce = (question) => {
    setResponceDrawer(true);
    setQuestion(question);
  };
  const handleKimReplies = () => {
    setRepliesDrawer(true);
  };
  const handleChange = (index, event) => {
    let value = event.target.value;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const startLoading = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = true;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };
  const addDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 90);

    return moment(result).format("DD MMM YYYY");
  };

  const hideLoading = (index) => {
    const length = questionMessages.length - 1;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = false;
    temp_element.isExpanded = false;
    temp_state[index] = temp_element;
    if (index !== length) {
      let temp_nex_element = { ...temp_state[index + 1] };
      temp_nex_element.isExpanded = true;
      temp_state[index + 1] = temp_nex_element;
    }
    setQuestionMessages(temp_state);
  };
  const user_profile = _get_user_from_localStorage();

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };

  const handleSubmitReply = () => {
    // console.log(inputs, "value of the accordian");
  };
  const dateConvert = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  };
  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };

    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    startLoading(index);
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    // console.log(temp_element, "temp_element index"); // for complete index
    const formDataObject = {
      question_id: temp_element._id,
      answer: temp_element.answer,
    };

    const result = await AddSingleAnswerApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      hideLoading(index);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      hideLoading(index);
    }
  };

  const handleSubmitAll = async (e, index) => {
    if (e !== "all") {
      e.preventDefault();
      startLoading(index);
    } else {
      setIsLoadingAll(true);
    }

    let arrayNew = {
      nineteen_day_vision: {
        aim: questionMessages[9].answer,
        achive_goal: questionMessages[8].answer,
        list_achive_goal: questionMessages[7].answer,
        list_habit_or_belife: questionMessages[6].answer,
        bullet_proof_attitide: questionMessages[5].answer,
        self_image_attainment: questionMessages[4].answer,
        analyse_your_activities: questionMessages[3].answer,
        take_action: questionMessages[2].answer,
        create_winning_formula: questionMessages[1].answer,
        rinse_and_repeat: questionMessages[0].answer,
        target_amount: amount,
        start_date: date,
      },
    };

    const result = await AddNineteenDay(arrayNew);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (e !== "all") {
        e.preventDefault();
        hideLoading(index);
      } else {
        setIsLoadingAll(false);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getNineteenDayQuestions();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12">
          {questionMessages.length > 0
            ? questionMessages.map((message, index) => {
                return (
                  <div className="card mb-2" key={message.id}>
                    <form
                      onSubmit={(e) => {
                        handleSubmitAll(e, index);
                      }}
                    >
                      <Accordion expanded={message.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-summary"
                        >
                          <Typography>
                            {htmlDecode(message.question_statement)}
                          </Typography>
                        </AccordionSummary>
                        <div
                          className="ms-3"
                          dangerouslySetInnerHTML={{
                            __html: message.description,
                          }}
                        ></div>

                        <AccordionDetails>
                          <TextField
                            id="filled-multiline-flexible"
                            fullWidth
                            multiline
                            rows={5}
                            label="Add Answer here"
                            className={
                              ((classes.dense, "textarea-color"), "text-field")
                            }
                            value={htmlDecode(message.answer)}
                            onChange={(e) => handleChange(index, e)}
                            variant="outlined"
                            name="message"
                          />

                          <div className="d-flex justify-content-between mt-3">
                            <button
                              className="small-contained-button ms-auto"
                              disabled={message.isLoading}
                            >
                              {message.isLoading ? "Saving..." : "Save & Next"}
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-between mt-3">
          <button
            className="small-contained-button ms-auto"
            disabled={isLoadingAll}
            onClick={() => {
              handleSubmitAll("all", "all");
            }}
          >
            {isLoadingAll ? "Saving..." : "Save All"}
          </button>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={responceDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${ownerName} Response`}
        componentToPassDown={<KimResponce />}
      />
      <CustomDrawer
        isOpenDrawer={repliesDrawer}
        onOpenDrawer={handleOpenRepliesDrawer}
        onCloseDrawer={handleCloseRepliesDrawer}
        pageTitle={`${ownerName} Replies`}
        componentToPassDown={<KimResponce />}
      />
    </>
  );
};

export default Message90Day;
