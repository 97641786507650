import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { gratitudesListing } from "../../DAL/Gratitude/Gratitude";
import { useSnackbar } from "notistack";
import EditGratitude from "./EditGratitude";
import AddGratitude from "./AddGratitude";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteGratitudeApi } from "../../DAL/Gratitude/Gratitude";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Gratitude() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [gratitudesData, setGratitudesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [gratitudeId, setGratitudeId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData } = useContentSetting();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleDetailPage = () => {};

  const getGratitudesListing = async () => {
    const result = await gratitudesListing();
    if (result.code === 200) {
      let gratitudeArray = [];
      let gratitudeObject = {};
      result.gratitude.map((gratitude, index) => {
        gratitudeObject = {
          pinteres_id: gratitude._id,
          pinterestImage: gratitude.gratitude_gallery.thumbnail_1,
          pinterestDescription: gratitude.gratitude_text,
          pinterestDate: gratitude.gratitude_date,
        };
        gratitudeArray.push(gratitudeObject);
      });
      setGratitudesData(gratitudeArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setGratitudeId(value.pinteres_id);
    setOpenDelete(true);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteGratitudeApi(gratitudeId);
    if (result.code === 200) {
      getGratitudesListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGratitudesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {contentSettingData && contentSettingData.gratitude_description ? (
              <div
                className="dashboard_description"
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.gratitude_description,
                }}
              ></div>
            ) : (
              <h2 className="quotes-heading">Gratitude</h2>
            )}
          </div>
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Gratitude
            </button>
          </div>
        </div>
        <div className="row">
          <PinterestLayout
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Gratitude?"}
            handleAgree={handleDelete}
            menuOptions={MENU_OPTIONS}
            data={gratitudesData}
            notFoundtitle="Gratitude"
            handleDetailPage={handleDetailPage}
          />
        </div>

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Gratitude"
          componentToPassDown={
            <EditGratitude
              dataList={getGratitudesListing}
              editData={editValues}
              onCloseDrawer={handleCloseEditDrawer}
            />
          }
        />

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="New Gratitude"
          componentToPassDown={
            <AddGratitude
              dataList={getGratitudesListing}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
      </div>
    </>
  );
}

export default Gratitude;
