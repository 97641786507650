import React, { useState } from "react";
import { TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AddCategories } from "../../../DAL/AffirmationCategories/Categories/Categories";
import { useSnackbar } from "notistack";

export default function AddCategory({ onCloseDrawer, dataList }) {
  const [previews, setPreviews] = useState("");
  const [image, setImage] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    categoryDescription: "",
    categoryTitle: "",
    // categoryImage: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    const fileList = event.target.files;
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  //Adding Category

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (image === "") {
      enqueueSnackbar("Image is required", { variant: "error" });
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("description", state.categoryDescription);
      formData.append("name", state.categoryTitle);
      formData.append("image", image);
      const result = await AddCategories(formData);
      if (result.code === 200) {
        onCloseDrawer();
        dataList();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Category Title"
          variant="outlined"
          name="categoryTitle"
          value={state.categoryTitle}
          required={true}
          onChange={(e) => handleChange(e)}
        />

        <TextField
          id="outlined-multiline-static"
          label="Category Description"
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%" }}
          name="categoryDescription"
          value={state.categoryDescription}
          onChange={(e) => handleChange(e)}
          className="mt-3 inputs-fields"
          required={true}
        />
        <p className="mt-2 mb-0">Recommended Size (1000x670)</p>
        <div className="col-md-12 d-flex">
          {previews && (
            <span className="preview mt-2">
              <span onClick={handleRemove}>x</span>
              <img src={previews} />
            </span>
          )}
          {previews == "" && (
            <span className="upload-button mt-2">
              <input
                color="primary"
                accept="image/*"
                type="file"
                id="icon-button-file"
                style={{ display: "none" }}
                name="affirmationImage"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <CloudUploadIcon />
              </label>
            </span>
          )}
        </div>
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
