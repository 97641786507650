import { invokeApi } from "../../bl_libs/invokeApi";

export const programmesListing = async (data) => {
  const requestObj = {
    path: `api/program`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const programmDetail = async (program_slug) => {
  const requestObj = {
    path: `api/program/get_program/${program_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const lessonList = async (program_slug) => {
  const requestObj = {
    path: `api/lesson/lesson_list_by_program/${program_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const StudySessionLis = async (session_slug) => {
  const requestObj = {
    path: `api/study_session_recording/study_session_recording_list_by_study_session/${session_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const StudySessionDetail = async (session_slug) => {
  const requestObj = {
    path: `api/study_session/study_session_detail/${session_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const lessonDetail = async (lesson_slug) => {
  const requestObj = {
    path: `api/lesson/${lesson_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const ProgramRecordingDetail = async (recording_slug) => {
  const requestObj = {
    path: `api/program_recording/detail/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const LessonRecordingDetail = async (recording_slug) => {
  const requestObj = {
    path: `api/lesson_recording/recording_detail/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const StudySessionRecordingDetailApi = async (recording_slug) => {
  const requestObj = {
    path: `api/study_session_recording/study_session_recording_detail/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const markelessonCompleted = async (lesson_slug) => {
  const requestObj = {
    path: `api/lesson/complete/${lesson_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const vaultCategorySidebarListing = async (data) => {
  const requestObj = {
    path: `api/program_recording/vault_category_side_bar_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const filteredRecordingList = async (loadMore, data) => {
  const requestObj = {
    path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const submitVaultFilter = async (data) => {
  const requestObj = {
    path: `api/program_recording/get_filtered_recording_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const consultantRecordingList = async (data) => {
  const requestObj = {
    path: `api/program_recording/get_consultant_filtered_recording_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
