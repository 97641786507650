import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, Card, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
// utils
import Label from "../../Label";
import { s3baseUrl } from "../../../config/config";

// ----------------------------------------------------------------------

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

ProgrammesCardFirstIndex.propTypes = {
  product: PropTypes.object,
};

export default function ProgrammesCardFirstIndex({ programm }) {
  const navigate = useNavigate();

  return (
    <Card
      className="programm-card h-100 secondary-background"
      sx={{ cursor: "pointer" }}
      onClick={() =>
        navigate(`/programmes/${programm?.program_slug}`, {
          state: programm,
        })
      }
    >
      <Box sx={{ position: "relative", cursor: "pointer" }}>
        {programm?.program_lock_status === true ? (
          <button
            variant="contained"
            className="small-contained-button program-lock-icon"
            style={{
              zIndex: 9,
              top: "45%",
              left: "40%",
              fontSize: "14px",
              position: "absolute",
              padding: "10px 20px",
            }}
          >
            <LockIcon />
            &nbsp;&nbsp;Unlock Access
          </button>
        ) : (
          ""
        )}
        <ProgrammesImgStyle
          alt={programm?.title}
          src={s3baseUrl + programm?.program_images?.thumbnail_1}
        />
      </Box>

      <Stack spacing={2} className="programme-content ">
        <h3>{programm?.title}</h3>
        <p className="programme-card-desc">{programm?.short_description}</p>
        <div className="card-button">
          <p className="programme-duration">
            <span>
              {programm?.no_of_lesson} Lessons &nbsp; | &nbsp;{" "}
              {programm?.total_lesson_duration}
            </span>
          </p>
        </div>
      </Stack>
    </Card>
  );
}
