import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AddNewEarning from "./AddNewEarning";
import { ThemeProvider, makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#1d1c1d",
  },
});

export default function SwipeableTemporaryDrawer({
  open,
  setOpenDrawer,
  hanldeClose,
  handleOpen,
}) {
  const classes = useStyles();
  return (
    <div className="add-earning-drawer">
      <React.Fragment>
        {/* <Button onClick={handleOpen}>Right Drawer</Button> */}
        <SwipeableDrawer
          classes={{ paper: classes.paper }}
          anchor="right"
          open={open}
          onClose={hanldeClose}
          // onOpen={toggleDrawer(anchor, true)}
        >
          <AddNewEarning close={hanldeClose} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
