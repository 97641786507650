import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { PodsListing } from "src/DAL/Pods/Pods";
import PodsCards from "../../components/Pods/Pods";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Pods() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [podsData, setPodsData] = useState([]);
  const getPodsListing = async () => {
    const result = await PodsListing();
    if (result.code === 200) {
      setPodsData(result.room);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (podsSlug) => {
    navigate(`/pods/pods-detail/${podsSlug}`);
  };

  useEffect(() => {
    getPodsListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <PodsCards
      handleNavigate={handleNavigate}
      data={podsData}
      notFoundtitle="Pods"
    />
  );
}

export default Pods;
