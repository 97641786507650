import React from "react";
import { Rating } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

const ReviewCardSlider = ({ headingData, data }) => {
  var data_length = data.length;
  let set_infinite = true;
  if (data_length < 3) {
    set_infinite = false;
  }
  var settings = {
    dots: false,
    autoplay: true,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(data, "datadata");
  return (
    <div className="container">
      <h2 className="mb-5">{htmlDecode(headingData)}</h2>
      <Slider {...settings} className="event-slider review-slider">
        {data.map((value, index) => (
          <div className="card card-border" key={value._id}>
            <div className="mt-2">
              <Rating
                className="rating-fill"
                name="half-rating"
                defaultValue={value.rating}
                initialRating={value.rating}
                readOnly
              />
            </div>
            <img
              src={s3baseUrl + value.images.thumbnail_2}
              className="card-img-top mt-2 m-auto w-100"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">{htmlDecode(value.name)} </h5>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default ReviewCardSlider;
