import React from "react";
import { Container, Stack, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
function GoalStatementList(props) {
  const navigate = useNavigate();
  const { contentSettingData } = useContentSetting();
  return (
    <>
      {contentSettingData.goal_statement_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: contentSettingData.goal_statement_description,
          }}
        ></div>
      ) : (
        <h2>Goal Statement</h2>
      )}
      <div className="row">
        <div className="col-12 mt-4">
          <Divider />
        </div>
        <div className="col-12 mt-4 text-end">
          <button
            className="small-contained-button "
            type="button"
            onClick={() => navigate(`/goal-statement/manage-photos`)}
          >
            Manage Photos
          </button>
        </div>
      </div>
    </>
  );
}

export default GoalStatementList;
