import React from "react";
import { ownerCompleteName, s3baseUrl } from "src/config/config";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";

const KimResponce = ({ question, data }) => {
  const { userInfo, adminTimeZone } = useContentSetting();
  return (
    <div className="container">
      <div className="row">
        {question && <p className="mt-3 mb-0">{htmlDecode(question)}</p>}
        {data.map((value, i) => {
          return (
            <div className="justify-content-center mt-2 mb-1 kims-reply-card">
              <div className="card-width text-center w-100">
                <div className="card-body">
                  <div className="row">
                    <div className="d-flex circle-image">
                      {value.image && (
                        <img src={s3baseUrl + value.image.thumbnail_1} />
                      )}
                      <div className="text-start set-title-width">
                        <p className="card-title pt-2 mb-0">
                          {ownerCompleteName}
                        </p>
                        <span className="date-color">
                          {get_date_with_user_time_zone(
                            value.createdAt,
                            "DD-MM-YYYY hh:mm:ss",
                            userInfo.time_zone,
                            adminTimeZone
                          )}
                        </span>
                      </div>
                    </div>
                    {value.audio_file && (
                      <div className="col-12 text-center">
                        <div>
                          <audio
                            controlsList="nodownload"
                            className="w-100"
                            src={s3baseUrl + value.audio_file}
                            controls
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-12 pt-2 responce-statement">
                      <p className="text-start">{htmlDecode(value.comment)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KimResponce;
