import { seedValue } from "faker";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TransactionList from "../Billing/TransactionList";
import Chip from "@mui/material/Chip";
import RequestCard from "../PaymentRequests/RequestCard";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TransactionCard from "../PaymentRequests/TransactionCard";
const TransactionInformation = (props) => {
  const billing = {
    id: 1,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    month: "10",
    request_type: "onetime",
    currency: "usd",
  };

  const navigate = useNavigate();

  return (
    <div className="container">
      <IconButton
        className="back-screen-button"
        onClick={() => navigate("/transaction")}
      >
        <ArrowBackIcon />
      </IconButton>
      <h2 className="mb-3">Billing Details</h2>
      <div className="col-12">
        <TransactionCard bill={billing} />
      </div>
      <div className="col-12">
        <TransactionList />
      </div>
    </div>
  );
};

export default TransactionInformation;
