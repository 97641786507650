import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, Card, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import { htmlDecode } from "src/utils/convertHtml";
// utils

// ----------------------------------------------------------------------

export default function DashboardCard({ data }) {
  const navigate = useNavigate();
  return (
    <Card className="programm-card h-100 secondary-background">
      <Stack spacing={2} className="programme-content ">
        <div
          className="programme-card-desc"
          dangerouslySetInnerHTML={{
            __html: htmlDecode(data.live_section_text),
          }}
        ></div>
        {data.live_section_button_link && (
          <div className="text-center w-100">
            <a href={data.live_section_button_link} target="_blank">
              <button className="small-contained-button">
                {data.live_section_button_text
                  ? htmlDecode(data.live_section_button_text)
                  : "JOIN NOW"}
              </button>
            </a>
          </div>
        )}
      </Stack>
    </Card>
  );
}
