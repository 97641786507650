import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";

import {
  communityfirecracker_1f9e8,
  communityHandScreenshot,
  communityHeart,
  communityMoneyImage,
  feedImage,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  portalFrontInteractive,
  profileImage,
  smallHeartIcon,
} from "src/assets";
import { Avatar, AvatarGroup } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import Comments from "./Comments";
import AddComment from "./AddComment";
import ShowAllLikes from "./ShowAllLikes";
function PostDetails() {
  const navigate = useNavigate();
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [showImageBox, setShowImageBox] = useState(false);
  const [showVideoBox, setShowVideoBox] = useState(false);

  const handleShowImageBox = () => {
    setShowImageBox(true);
    setShowVideoBox(false);
  };

  const handleShowVideoBox = () => {
    setShowVideoBox(true);
    setShowImageBox(false);
  };

  const handleOpenSimpleBox = () => {
    setOpenCreateModal(true);
    setShowImageBox(false);
    setShowVideoBox(false);
  };

  const handlePage = () => {
    navigate(`/edit-profile`);
  };

  return (
    <div className="container new-community-page post-detail-page">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/dashboard")}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="profile-cards p-3 mb-3 main-section">
        <div className="d-flex comment-image w-100 justify-content-between">
          <div className="poster-name-image d-flex">
            <img src={profileImage} className="p-0" />
            <div className="text-start set-title-width poster-name">
              <p className="card-title pt-2 mb-0">Leeanna Alvord</p>
              <span className="date-color">12 Dec 2018 at 1:16 AM</span>
            </div>
          </div>

          <div className="poster-logo text-end">
            <img src={portalFrontInteractive} alt="" />
          </div>
        </div>
        <div className="mt-2">
          <p>
            Wonderful Machine· A well-written bio allows viewers to get to know
            a photographer beyond the work. This can make the difference when
            presenting to clients who are looking for the perfect fit.
          </p>
        </div>
        <div className="feed-image">
          <img src={feedImage} />
        </div>
        <div className="my-2 comment-icon d-flex justify-content-between">
          <div className="svg-color mt-1 comment-icons">
            <div className="likes-post d-flex ps-2">
              <img src={communityHeart} alt="" />
              &nbsp;
              <AvatarGroup max={3}>
                <Avatar alt="Remy Sharp" src={gallery1} />
                <Avatar alt="Travis Howard" src={gallery2} />
                <Avatar alt="Cindy Baker" src={gallery3} />
                <Avatar alt="Agnes Walker" src={gallery4} />
                <Avatar alt="Trevor Henderson" src={gallery6} />
              </AvatarGroup>
              <span
              // onClick={() => {
              //   setOpenLikesModal(true);
              // }}
              >
                &nbsp;and 7 others
              </span>
            </div>
          </div>
          <div className="mt-1 likes-post">
            <div className="likes-post d-flex ps-2">
              <img src={communityHandScreenshot} alt="" />
              &nbsp;
              <AvatarGroup max={3}>
                <Avatar alt="Remy Sharp" src={gallery1} />
                <Avatar alt="Travis Howard" src={gallery2} />
                <Avatar alt="Cindy Baker" src={gallery3} />
                <Avatar alt="Agnes Walker" src={gallery4} />
                <Avatar alt="Trevor Henderson" src={gallery6} />
              </AvatarGroup>
              <span
              // onClick={() => {
              //   setOpenLikesModal(true);
              // }}
              >
                &nbsp;and 7 others
              </span>
            </div>
          </div>
          <div className="likes-post mt-1 pe-3 text-end">
            <img src={communityMoneyImage} alt="" /> = 1000
          </div>
        </div>
        <hr />
        <div className="row comment-icon">
          <div className="col-4 svg-color comment-icons text-center">
            <Chip
              label={
                <>
                  <img src={communityHeart} alt="" /> Like
                </>
              }
              variant="outlined"
            />
          </div>
          <div className="col-4 svg-color comment-icons text-center">
            <Chip
              label={
                <>
                  <ChatBubbleOutlineIcon />
                  &nbsp; Comment
                </>
              }
              variant="outlined"
            />
          </div>
          <div className="col-4 svg-color comment-icons text-center">
            <Chip
              label={
                <>
                  <img src={communityHandScreenshot} alt="" />
                  &nbsp; Gratitude
                </>
              }
              variant="outlined"
            />
          </div>
        </div>
        <hr />
        <div className="profile-comments">
          <Comments />
          <AddComment />
        </div>
      </div>
      <ShowAllLikes
        openLikesModal={openLikesModal}
        setOpenLikesModal={setOpenLikesModal}
      />
    </div>
  );
}

export default PostDetails;
