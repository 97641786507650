import React from "react";
import { Box, Card, Link, Typography, Stack, Button } from "@mui/material";
import bugFilled from "@iconify/icons-ant-design/bug-filled";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from "moment";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { convertCurrencyToSign } from "src/utils/constants";
import outlineDateRange from "@iconify/icons-ic/outline-date-range";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import { htmlDecode } from "src/utils/convertHtml";

function RequestCard({ bill, handlePage, btn_title }) {
  return (
    <Card className="payment-card mb-3">
      <div className="row p-3">
        <div className="col-12">
          <div className="mb-3">
            <span>{htmlDecode(bill.request_title)}</span>
          </div>
          <div className="mb-3 d-flex align-items-center svg-color date-color">
            <Icon icon="uiw:date" />
            <span className="ms-2">
              {moment(bill.createdAt).format("MMM DD YYYY")}
            </span>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 col-lg-3 request-type">
              Request Type:
            </div>
            <div className="col-4 col-md-6 col-lg-3 ">
              {bill.request_type === "onetime" ? "OneTime" : "Recurring"}
            </div>
            <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0 request-type">
              Total Programme Amount:
            </div>
            <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 ">
              {convertCurrencyToSign(bill.currency)}
              {bill.total_amount.toLocaleString()}
            </div>

            {bill.request_type === "recurring" && (
              <>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Initial Deposit Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.initial_amount.toLocaleString()}
                </div>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Monthly Installments:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.installment_amount.toLocaleString()}
                </div>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Installments Plan:
                </div>
                <div className="col-4 col-md-6 col-lg-3 mt-3 ">
                  {bill.month + " Months"}
                </div>
              </>
            )}
            {handlePage && (
              <div className="col-12 text-center">
                <button
                  className="small-contained-button mt-3"
                  onClick={() => {
                    handlePage(
                      bill.payment_request_type,
                      bill.payment_request_slug
                    );
                  }}
                >
                  {btn_title}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RequestCard;
