import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { useNavigate } from "react-router-dom";
import AddMemories from "./AddMemories";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { memoriesListing, DeleteMomentdeApi } from "src/DAL/Memories/Memories";
import { EditMemories } from "..";
import { useSnackbar } from "notistack";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Memories(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [memoriesData, setMemoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [memoryslug, setMemorySlug] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData } = useContentSetting();
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/moment/get_all_magic_moment?page=0&limit=10"
  );

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const getMemoriesListing = async () => {
    const result = await memoriesListing(loadMore);
    if (result.code === 200) {
      let memoryArray = [];
      let memoryObject = {};
      result.magicMoment.map((memory, index) => {
        memoryObject = {
          pinteres_id: memory._id,
          pinterestImage: memory.moment_image[0].thumbnail_2,
          memoriesImages: memory.moment_image,
          pinterestDescription: memory.moment_description,
          pinterestTitle: memory.moment_title,
          pinterestDate: memory.moment_date,
          pinterestSlug: memory.moment_slug,
        };
        memoryArray.push(memoryObject);
      });
      var newArray = memoriesData.concat(memoryArray);
      setMemoriesData(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };
  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setMemorySlug(value.pinterestSlug);
    setOpenDelete(true);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    let myArray = [];
    const result = await DeleteMomentdeApi(memoryslug);
    if (result.code === 200) {
      myArray = memoriesData.filter(function (obj) {
        return obj.pinterestSlug !== memoryslug;
      });
      setMemoriesData(myArray);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  useEffect(() => {
    getMemoriesListing();
  }, []);

  useEffect(() => {
    getMemoriesListing();
  }, [loadMore]);

  const handleDetailPage = (pinterestSlug) => {
    navigate(`/memories/memories_detail/${pinterestSlug}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {contentSettingData && contentSettingData.memories_description ? (
              <div
                className="dashboard_description"
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.memories_description,
                }}
              ></div>
            ) : (
              <h2 className="quotes-heading">Memories</h2>
            )}
          </div>
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Memories
            </button>
          </div>
        </div>
        <div className="row">
          <PinterestLayout
            curserPointer={true}
            handleDetailPage={handleDetailPage}
            data={memoriesData}
            notFoundtitle="Memories"
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Moment?"}
            handleAgree={handleDelete}
            menuOptions={MENU_OPTIONS}
          />
          {totlePages > pageNumber ? (
            <div className="col-12 text-center">
              <button
                className="small-outlined-button mt-3"
                onClick={loadMoreData}
                disabled={isLoadingMore}
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Moment"
        componentToPassDown={
          <EditMemories
            dataList={memoriesData}
            editData={editValues}
            setEditValues={setEditValues}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Moment"
        componentToPassDown={
          <AddMemories
            dataList={memoriesData}
            setMemoriesData={setMemoriesData}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default Memories;
