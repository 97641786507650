import React from "react";
import { ReactVideoPlayer } from "src/components";
import { Container, Stack, Typography, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Message90Day from "src/components/message/Message90Day";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const NintyDayPlan = () => {
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const { contentSettingData } = useContentSetting();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container">
      {/* <h2>90 Day Plan</h2> */}
      <div className="row mt-3">
        {contentSettingData &&
        contentSettingData.ninteen_day_plan_description ? (
          <div
            className="dashboard_description"
            dangerouslySetInnerHTML={{
              __html: contentSettingData.ninteen_day_plan_description,
            }}
          ></div>
        ) : (
          <h2 className="quotes-heading">90 Day Plan</h2>
        )}

        {/* <p>
          Etiam imperdiet facilisis magna, a faucibus purus gravida ut.
          Vestibulum vehicula malesuada eros, vel volutpat nulla aliquet sed.
          Mauris ut augue id metus semper scelerisque ut ut ante.
        </p>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <ReactVideoPlayer url="https://vimeo.com/226053498" />
        </div> */}
        <div className="col-12 mt-4">
          <Divider />
        </div>
        <div className="col-12 mt-4 text-end"></div>
      </div>

      <div className="col-12">
        <Message90Day />
      </div>
    </div>
  );
};

export default NintyDayPlan;
